<template>
  <div class="featured-article" v-show="article.link">
    <div class="row">
      <div class="col-lg-4 d-none d-lg-block">
        <img
          :src="article.image_url"
          alt="featured article image"
          class="img-fluid"
        />
      </div>
      <div class="col-12 col-lg-8">
        <h2>FEATURED ARTICLE</h2>
        <h3><a :href="article.link">{{ article.title }}</a></h3>
        <h4>{{ fullDate }} by: <a :href="article.author?.link">{{ authorName }}</a></h4>
        <p class="d-inline" v-html="articleAndLinkHtml"></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.$store.dispatch("fetchFeaturedArticle");
  },
  methods: {
    sanitize(text) {
      return this.$sanitize(text);
    },
  },
  computed: {
    article() {
      return this.$store.state.featuredArticle;
    },
    articleAndLinkHtml() {
      return this.sanitize(`${this.article.excerpt} <a class="d-inline" href="${this.article.link}">read more &raquo;</a>`)
    },
    authorName() {
      return this.article.author?.name.split(" ")[0];
    },
    fullDate() {
      const d = new Date(this.article.post_date);
      return d.toLocaleString("default", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
div.featured-article {
  background-color: $light-bg-color;

  h2,
  p {
    color: black;
  }

  h2 {
    margin-top: 21px;
    margin-bottom: 6px;
    font-family: $primary-font-family;
    font-size: 1.5rem;
    font-weight: 900;
  }

  h3 {
    font-size: 1.25rem;
    color: $link-color-dark;
    font-weight: 900;

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  h4 {
    color: $secondary-font-color-dark;
    font-size: 0.825rem;
    font-weight: 700;

    a {
      color: #3b9bf5;
      text-decoration: none;
      font-size: 0.825rem;
    }
  }

  p, a {
   font-size: 0.925rem; 
  }

  a {
    color: #3b9bf5;
    text-decoration: underline;
  }
}
</style>
